import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/method.module.css'

const Method = () => {
  return (
    <div className={styles.methodContainer}>
      <h1 className={styles.methodTitle}>「好き」を仕事にする方法</h1>
      <p className={styles.methodDescription}>
        「好き」を仕事にすると言っても・・・<br/>
        <br/>
        <strong>✔︎ 本当に自分が好きな仕事が分からない</strong><br/>
        <strong>✔︎ 好きなことを仕事にする方法が分からない</strong><br/>
        <strong>✔︎ やりたいことは見つかっているけど踏み出せない</strong>
        <br/>
        <br/>
        <br/>
        その前に<br/>
        <strong>なぜ「好きなこと」を<br/>仕事にすると良いのでしょうか？</strong>
      </p>
      <div className={styles.methodRightGrid}>
        <div className={styles.methodImageWrapper}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            className={styles.methodImage}
            alt="好きなことをしている時は時間を忘れるほど熱中できますよね。"
            src="../images/method-1.png"
          />
        </div>
        <p className={styles.methodText}>
          好きなことをしている時は<br/>
          時間を忘れるほど熱中できますよね。<br/>
          <br/>
          それが仕事だったら・・・
        </p>
      </div>
      <div className={styles.methodLeftGrid}>
        <div className={styles.methodImageWrapper}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            className={styles.methodImage}
            alt="パフォーマンスは最大になります！"
            src="../images/method-2.png"
          />
        </div>
        <p className={styles.methodText}>
          パフォーマンスは最大になります！<br/>
          <br/>
          常に前向きな姿勢で、<br/>仕事に取り組むことができます。
        </p>
      </div>
      <div className={styles.methodRightGrid}>
        <div className={styles.methodImageWrapper}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            className={styles.methodImage}
            alt="好きなだけでは仕事にならないことが多いのも現実です..."
            src="../images/method-3.png"
          />
        </div>
        <p className={styles.methodText}>
          しかしながら、<br/>
          <br/>
          <strong>好きなだけでは仕事にならない</strong><br/>
          ことが多いのも現実です...
        </p>
      </div>
      <div className={styles.methodLeftGrid}>
        <div className={styles.methodImageWrapper}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            className={styles.methodImage}
            alt="「好き」と「仕事」の交差点を見つける必要があります。"
            src="../images/method-4.png"
          />
        </div>
        <p className={styles.methodText}>
          好きなことを仕事にするには、<br/>
          <br/>
          世の中の需要とのクロスポイント<br/>
          <span className={styles.methodPunchLine}>「好き」と「仕事」の交差点</span><br/>
          を見つける必要があります。
        </p>
      </div>
    </div>
  )
}

export default Method
