import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/mission.module.css'

const Mission = () => {
  const breakpoints = useBreakpoint()

  return (
    <div id="mission" className={styles.missionContainer}>
      <StaticImage
        className={styles.missionBorderImage}
        alt="Border line"
        src="../images/curve-green-top.png"
      />
      <h1 className={styles.missionTitleEn}>Our Mission</h1>
      <h2 className={styles.missionTitleJp}>
        「好きを仕事に」を応援し、{breakpoints.xs || breakpoints.sm || breakpoints.md ? <br/> : null}成長させる
      </h2>
      <p style={{paddingBottom:"20px"}}>
        自分が本当に好きなことは何なのか？<br/>
        それを仕事にするにはどうしたら良いか・・<br/>
        <br/>
        インターネットやSNSの普及により、<br/>
        ひと昔前では、無謀だと言われるようなことでも、<br/>
        実現できる可能性が高まってきました。<br/>
        <br/>
        例えば、私たちの場合、<br/>
        <br/>
        「カフェや雑貨屋をやってみたい」<br/>
        「フリーランスとして独立したい」<br/>
        「海外に住んでみたい」<br/>
        <br/>
        といった「夢」を実現させてきました。<br/>
        <br/>
        このような経験を活かして、<br/>
        「好きなこと」を見つけ、成長させる<br/>
        お手伝いができればと思っています。
      </p>
      <StaticImage
        className={styles.missionImage}
        alt="「好きを仕事に」を応援し、成長させる"
        src="../images/mission.png"
      />
      <StaticImage
        className={styles.missionBorderImage}
        alt="Border line"
        src="../images/curve-green-bottom.png"
      />
    </div>
  )
}

export default Mission
