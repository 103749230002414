// extracted by mini-css-extract-plugin
export var blogListBorderImage = "blog-list-module--blog-list-border-image--FRR1v";
export var blogListButton = "blog-list-module--blog-list-button--zSPEp";
export var blogListCard = "blog-list-module--blog-list-card--s19cd";
export var blogListCardDate = "blog-list-module--blog-list-card-date--bG-+3";
export var blogListCardImage = "blog-list-module--blog-list-card-image--XWVCA";
export var blogListCardImageWrapper = "blog-list-module--blog-list-card-image-wrapper--jEICu";
export var blogListCardLink = "blog-list-module--blog-list-card-link--nIatz";
export var blogListCardTag = "blog-list-module--blog-list-card-tag--wFHKO";
export var blogListCardText = "blog-list-module--blog-list-card-text--FbaSp";
export var blogListCardTitle = "blog-list-module--blog-list-card-title--IgNHX";
export var blogListCardWrapper = "blog-list-module--blog-list-card-wrapper--QFVJl";
export var blogListContainer = "blog-list-module--blog-list-container--kxY9L";
export var blogListText = "blog-list-module--blog-list-text--+Hulc";
export var blogListTitle = "blog-list-module--blog-list-title--PFszV";