// extracted by mini-css-extract-plugin
export var flowBorderImage = "flow-module--flow-border-image--h0CO2";
export var flowCard = "flow-module--flow-card--x3f1T";
export var flowCardBody = "flow-module--flow-card-body--hPm85";
export var flowCardHeader = "flow-module--flow-card-header--8Lzuh";
export var flowCardHeaderCushion = "flow-module--flow-card-header-cushion--GwoMN";
export var flowCardHeaderNumber = "flow-module--flow-card-header-number--Qy1It";
export var flowCardHeaderNumberWrapper = "flow-module--flow-card-header-number-wrapper--IZoKm";
export var flowCardHeaderText = "flow-module--flow-card-header-text--jzwnS";
export var flowCardWrapper = "flow-module--flow-card-wrapper--ifJnd";
export var flowContainer = "flow-module--flow-container--u0eb4";
export var flowTitleEn = "flow-module--flow-title-en--iO0HX";
export var flowTitleJp = "flow-module--flow-title-jp--7RdCQ";