// extracted by mini-css-extract-plugin
export var serviceBorderImage = "service-module--service-border-image--r1nV9";
export var serviceCard = "service-module--service-card--qcou5";
export var serviceCardBody = "service-module--service-card-body--HJby+";
export var serviceCardCourse = "service-module--service-card-course--ToJ-Y";
export var serviceCardHeader = "service-module--service-card-header--ozs3m";
export var serviceCardIcon = "service-module--service-card-icon--D1nnB";
export var serviceCardImageWrapper = "service-module--service-card-image-wrapper--tmP4h";
export var serviceCardOuter = "service-module--service-card-outer--5mq6M";
export var serviceCardPrice = "service-module--service-card-price--7+z90";
export var serviceCardPriceClosed = "service-module--service-card-price-closed--q9YhT";
export var serviceCardPriceClosedLink = "service-module--service-card-price-closed-link--vME2m";
export var serviceCardPriceFee = "service-module--service-card-price-fee--Wsf2l";
export var serviceCardTag = "service-module--service-card-tag--7S04f";
export var serviceCardTagLabel = "service-module--service-card-tag-label--6yKXV";
export var serviceCardTagsGrid = "service-module--service-card-tags-grid--pG1uF";
export var serviceCardTitle = "service-module--service-card-title--tRoy9";
export var serviceCardWrapper = "service-module--service-card-wrapper--LOtmJ";
export var serviceContainer = "service-module--service-container--pHMY+";
export var serviceReedMoreButton = "service-module--service-reed-more-button--YWWe9";
export var serviceReedMoreCushion = "service-module--service-reed-more-cushion--SAJY2";
export var serviceReedMoreText = "service-module--service-reed-more-text--gWchz";
export var serviceReedMoreWrapper = "service-module--service-reed-more-wrapper--DBwtZ";
export var serviceTitleEn = "service-module--service-title-en--KE48z";
export var serviceTitleJp = "service-module--service-title-jp--mxrBq";