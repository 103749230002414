import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/faq.module.css'

const Faq = () => {
  return (
    <div id="faq" className={styles.faqContainer}>
      <StaticImage
        className={styles.faqBorderImage}
        alt="Border line"
        src="../images/curve-clear.png"
      />
      <h1 className={styles.faqTitleEn}>FAQ</h1>
      <h2 className={styles.faqTitleJp}>よくあるご質問</h2>
      <details className={styles.faqDetails}>
        <summary className={styles.faqSummary}>
          <div className={styles.faqSummaryInner}>
            <div className={styles.faqSummaryQuestion}>
              <div className={styles.faqSummaryCushionWrapper}>
                <StaticImage
                  className={styles.faqSummaryCushion}
                  alt="Question"
                  src="../images/cushion-blobs.png"
                />
                <p className={styles.faqSummaryQuestionInitial}>Q</p>
              </div>
              <p className={styles.faqSummaryQuestionTitle}>1度だけ添削やアドバイスを受けることはできますか？</p>
            </div>
            <span className={styles.faqSummaryArrow}>▼</span>
          </div>
        </summary>
        <p className={styles.faqAnswer}>
          <strong>A.</strong> もちろんです。スクールやコーチングは、1回単位でお申し込みいただくことが可能です。<br/>
          必要に応じて、都合の良いタイミングでご利用くださいませ。
        </p>
      </details>
      <details className={styles.faqDetails}>
        <summary className={styles.faqSummary}>
          <div className={styles.faqSummaryInner}>
            <div className={styles.faqSummaryQuestion}>
              <div className={styles.faqSummaryCushionWrapper}>
                <StaticImage
                  className={styles.faqSummaryCushion}
                  alt="Question"
                  src="../images/cushion-blobs.png"
                />
                <p className={styles.faqSummaryQuestionInitial}>Q</p>
              </div>
              <p className={styles.faqSummaryQuestionTitle}>Webサイトおまかせコースは1年以降も継続可能ですか？</p>
            </div>
            <span className={styles.faqSummaryArrow}>▼</span>
          </div>
        </summary>
        <p className={styles.faqAnswer}>
          <strong>A.</strong> 可能です。その場合は、引き続き毎月¥6,000お支払いいただきます。<br/>
          また、譲渡することも可能ですが、更新や修正をしたい場合は、ご自身でコーディングしていただくか、単発でご依頼いただく必要があります。
        </p>
      </details>
      <details className={styles.faqDetails}>
        <summary className={styles.faqSummary}>
          <div className={styles.faqSummaryInner}>
            <div className={styles.faqSummaryQuestion}>
              <div className={styles.faqSummaryCushionWrapper}>
                <StaticImage
                  className={styles.faqSummaryCushion}
                  alt="Question"
                  src="../images/cushion-blobs.png"
                />
                <p className={styles.faqSummaryQuestionInitial}>Q</p>
              </div>
              <p className={styles.faqSummaryQuestionTitle}>海外移住の相談は可能ですか？</p>
            </div>
            <span className={styles.faqSummaryArrow}>▼</span>
          </div>
        </summary>
        <p className={styles.faqAnswer}>
          <strong>A.</strong> はい。私たちが実際に経験した、オランダ移住、ジョージア移住についてのご相談は可能です。
          海外移住のご相談の場合、ライフコーチングという形で承っております。
        </p>
      </details>
    </div>
  )
}

export default Faq
