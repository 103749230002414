import * as React from 'react'
import * as styles from '../styles/contact.module.css'

const Contact = () => {
  return (
    <div id="contact" className={styles.contactContainer}>
      <h1 className={styles.contactTitleEn}>Contact us</h1>
      <h2 className={styles.contactTitleJp}>お問い合わせ</h2>
      <form action="/success" className={styles.contactForm} name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <p><input required className={styles.contactFormInput} type="text" name="name" placeholder="お名前"/></p>
        <p><input required className={styles.contactFormInput} type="email" name="email" placeholder="メールアドレス"/></p>
        <p><textarea required className={styles.contactFormTextArea} name="message" placeholder="お問い合わせ内容"></textarea></p>
        <p><button className={styles.contactFormButton} type="submit">上記の内容で送信する</button></p>
      </form>
    </div>
  )
}

export default Contact
