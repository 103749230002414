import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/staff.module.css'

const Staff = () => {
  return (
    <div id="staff" className={styles.staffContainer}>
      <div className={styles.staffInner}>
        <div className={styles.staffCard}>
          <StaticImage
            className={styles.staffImage}
            alt="Ami"
            src="../images/staff-ami.png"
          />
          <div className={styles.staffHeader}>
            <h2 className={styles.staffName}>Ami</h2>
            <a href="https://www.instagram.com/amihuis_ami/" target="_blank" rel="noopener noreferrer">
              <StaticImage
                alt="Ami | Instagram"
                src="../images/logo-instagram.svg"
              />
            </a>
          </div>
          <div className={styles.readmore}>
            <input id="profile-ami" className={styles.readmoreCheck} type="checkbox" />
            <div className={styles.readmoreContent}>
              <p>
                1982年、名古屋生まれ。<br/>
                Webデザイン / 動画編集 / イラスト / カフェ・雑貨店経営 / 買い付け / 編み物講師<br/>
                <br/>
                幼少期から絵を描くことが好きで、学生時代は映画研究部に所属し、自分で映画上映会を企画してはフライヤーを作るなど、デザインに没頭する日々を過ごしました。<br/>
                大学卒業後、東京のWeb制会社に就職し、Webデザイナーとして働いていましたが、体調を崩してしまい地元名古屋へ帰郷。<br/>
                <br/>
                散歩中に一目惚れした物件をノープランで契約し、雑貨屋をオープン。海外で買い付けた雑貨の販売や、自分自身も編み物講師をしたりしていました。<br/>
                さらに、人が集まる場所を作りたいと思い、名古屋に2店舗目となる自家焙煎コーヒーのカフェをオープン。<br/>
                <br/>
                買い付けやデザインの幅を広げるため、そして新たな知見を得るため、店舗運営は両親へ引き継ぎ、オランダへ移住。<br/>
                オランダを拠点にヨーロッパ各国を回って買い付けをするなかで、訪れた国の歴史や文化なども伝えたいと思い、動画編集やYouTubeを始めました。<br/>
                そして2021年、慣れてきたオランダを離れ、さらなる挑戦をするために東欧ジョージアへ移住。<br/>
                ジョージア生活をSNSで発信しながら、デザインやイラストの腕を磨いています。
              </p>
            </div>
            <div className={styles.readmoreContentMask}></div>
            <label className={styles.readmoreLabel} for="profile-ami"></label>
          </div>
        </div>
        <div className={styles.staffCard}>
          <StaticImage
            className={styles.staffImage}
            alt="Shoei"
            src="../images/staff-shoei.png"
          />
          <div className={styles.staffHeader}>
            <h2 className={styles.staffName}>Shoei</h2>
            <a href="https://www.instagram.com/shoei_georgia/" target="_blank" rel="noopener noreferrer">
              <StaticImage
                alt="Shoei | Instagram"
                src="../images/logo-instagram.svg"
              />
            </a>
          </div>
          <div className={styles.readmore}>
            <input id="profile-shoei" className={styles.readmoreCheck} type="checkbox" />
            <div className={styles.readmoreContent}>
              <p>
                1982年、名古屋生まれ。<br/>
                プログラマー / Web開発・制作 / プログラミング講師 / ライター / ワールドミュージックDJ<br/>
                <br/>
                農業系の大学を卒業後、アメリカワシントン州のリンゴ農場や鉢花温室で働く、土にまみれた生活から一転。
                「いろいろな国へ行ってみたい」という不純な動機で「プログラマー」という文字通り畑違いの世界へ飛び込み、
                全くの未経験ながらも、シンガポール、韓国、アメリカテキサス州などへの長期出張を経験しました。<br/>
                <br/>
                その後、商社での営業マン、ハードウェアのエンジニアといった、いわゆるサラリーマン生活を経て、Webプログラマーとして個人事業を起業。<br/>
                1年後に拠点をオランダへ移し、Web開発・制作の事業を続ける中で、有難いことにプログラミングスクールの講師や、Webライターという肩書きも増えました。<br/>
                <br/>
                約3年間のオランダ生活を経て、新規事業への挑戦、日本や第3国との複数拠点生活を実現するため、2021年9月ジョージアへ移住。<br/>
                休日はライフワークでもあるDJの腕を磨くため、日々怪しげなレコードを漁っています。笑
              </p>
            </div>
            <div className={styles.readmoreContentMask}></div>
            <label className={styles.readmoreLabel} for="profile-shoei"></label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Staff
