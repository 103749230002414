import * as React from 'react'
import Layout from '../components/layout'
import MainVisual from '../components/main-visual'
import Method from '../components/method'
import Mission from "../components/mission"
import About from '../components/about'
import Staff from '../components/staff'
import Service from '../components/service'
import Works from '../components/works'
import Flow from '../components/flow'
import Voices from '../components/voices'
import Faq from '../components/faq'
import BlogList from '../components/blog-list'
import Contact from '../components/contact'
import sal from 'sal.js'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true
  })
}

const IndexPage = () => {
  React.useEffect(() => {
    sal({
      threshold: 0.25,
    })
  }, [])

  return (
    <Layout isHome={true}>
      <MainVisual/>
      <Method/>
      <Mission/>
      <About/>
      <Staff/>
      <Service/>
      <Works/>
      <Flow/>
      <Voices/>
      <Faq/>
      <BlogList/>
      <Contact/>
    </Layout>
  )
}

export default IndexPage
