// extracted by mini-css-extract-plugin
export var faqAnswer = "faq-module--faq-answer--gIqf1";
export var faqBorderImage = "faq-module--faq-border-image--GqZ-c";
export var faqContainer = "faq-module--faq-container--rG5qw";
export var faqDetails = "faq-module--faq-details--U3uIl";
export var faqSummary = "faq-module--faq-summary--ZHCH+";
export var faqSummaryArrow = "faq-module--faq-summary-arrow--NYewO";
export var faqSummaryCushion = "faq-module--faq-summary-cushion--FdYkq";
export var faqSummaryCushionWrapper = "faq-module--faq-summary-cushion-wrapper--N3kUv";
export var faqSummaryInner = "faq-module--faq-summary-inner--mN2I8";
export var faqSummaryQuestion = "faq-module--faq-summary-question--7QoLk";
export var faqSummaryQuestionInitial = "faq-module--faq-summary-question-initial--y6F7U";
export var faqSummaryQuestionTitle = "faq-module--faq-summary-question-title--vN+CN";
export var faqTitleEn = "faq-module--faq-title-en--Sp0fn";
export var faqTitleJp = "faq-module--faq-title-jp--1nsL6";