import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/service.module.css'

const Service = () => {
  const breakpoints = useBreakpoint()

  return (
    <div id="service" className={styles.serviceContainer}>
      <StaticImage
        className={styles.serviceBorderImage}
        alt="Border line"
        src="../images/curve-clear.png"
      />
      <h1 className={styles.serviceTitleEn}>Our Service</h1>
      <h2 className={styles.serviceTitleJp}>3つのプランをご用意しています</h2>
      <div className={styles.serviceCardWrapper}>
        <div className={styles.serviceCard}>
          <div className={styles.serviceCardBody}>
            <div className={styles.serviceCardHeader}>
              <h2 className={styles.serviceCardTitle}>クリエイティブスクール</h2>
              {/* <p className={styles.serviceCardPrice}>月額<span className={styles.serviceCardPriceFee}>25,000</span>円</p>
              <p className={styles.serviceCardPrice} style={{fontWeight: "normal", margin: "5px 0 0"}}>(4ヶ月コースの場合)</p> */}
              <p className={styles.serviceCardPriceClosed}>募集を締め切りました。</p>
              <p className={styles.serviceCardPriceClosed}>次回の募集までお待ちください。</p>
            </div>
            <div
              className={styles.serviceCardTagsGrid}
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease-out-cubic"
            >
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="未経験から"
                  src="../images/icon-leaf.png"
                />
                <p className={styles.serviceCardTagLabel}>未経験から</p>
              </div>
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="質問し放題"
                  src="../images/icon-fukidashi.png"
                />
                <p className={styles.serviceCardTagLabel}>質問し放題</p>
              </div>
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="マンツーマン"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.serviceCardTagLabel}>マンツーマン</p>
              </div>
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="お手頃料金"
                  src="../images/icon-money.png"
                />
                <p className={styles.serviceCardTagLabel}>お手頃料金</p>
              </div>
              {/* <Link to="/school"
                className={styles.serviceReedMoreWrapper}
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease-out-cubic"
              >
                <div className={styles.serviceReedMoreButton}>
                  <StaticImage
                    className={styles.serviceReedMoreCushion}
                    alt="詳細を見る"
                    src="../images/cushion-blobs.png"
                  />
                  <p className={styles.serviceReedMoreText}>詳細<br/>を見る</p>
                </div>
              </Link> */}
            </div>
          </div>
          <div
            className={styles.serviceCardImageWrapper}
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              imgStyle={{borderRadius: "40% 0% 0% 40% / 50% 0% 0% 50%"}}
              alt="Border line"
              src="../images/service-creative-school.jpg"
            />
          </div>
        </div>
        <StaticImage
          className={styles.serviceBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        />
        <div className={styles.serviceCardOuter}>
          <div className={styles.serviceCard} style={{backgroundColor: "#ECEDD4", padding: "20px 0 40px", margin: "0"}}>
            <div
              className={styles.serviceCardImageWrapper}
              style={breakpoints.xs || breakpoints.sm || breakpoints.md
                ? {margin: "10px -90px 0 0"}
                : {margin: "0 -250px 0 0"}
              }
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease-out-cubic"
            >
              <StaticImage
                imgStyle={{borderRadius: "0% 40% 40% 0% / 0% 50% 50% 0%"}}
                alt="Border line"
                src="../images/service-creative-works.jpg"
              />
            </div>
            <div className={styles.serviceCardBody} style={{display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right"}}>
              <div className={styles.serviceCardHeader} style={{margin: "0 15px 0 0"}}>
                <h2 className={styles.serviceCardTitle} style={{margin: "10px 0 25px"}}>クリエイティブ制作</h2>
                <p className={styles.serviceCardCourse}>Webサイトおまかせコース</p>
                {/* <p className={styles.serviceCardPrice}>月額<span className={styles.serviceCardPriceFee}>6,000</span>円</p>
                <p className={styles.serviceCardPrice} style={{fontWeight: "normal", margin: "5px 0 0"}}>(1年間サブスクリプション)</p> */}
                <p className={styles.serviceCardPriceClosed}>新規受付を締め切りました。</p>
                <p className={styles.serviceCardPriceClosed}>
                  お問い合わせは
                  <Link to="/#contact" className={styles.serviceCardPriceClosedLink}>こちら</Link>
                </p>
              </div>
              <div
                className={styles.serviceCardTagsGrid}
                style={breakpoints.xs || breakpoints.sm || breakpoints.md
                  ? {borderRadius: "20% 0% 0% 20% / 50% 0% 0% 50%", width: "220px", padding: "15px 10px 15px 40px", margin: "30px 0 0 0"}
                  : {borderRadius: "30% 0% 0% 30% / 50% 0% 0% 50%", padding: "15px 0 15px 100px"}
                }
                data-sal="slide-left"
                data-sal-duration="1000"
                data-sal-easing="ease-out-cubic"
              >
                <div className={styles.serviceCardTag} style={{margin: "1px 0"}}>
                  <StaticImage
                    className={styles.serviceCardIcon}
                    alt="運用費0円"
                    src="../images/icon-check.png"
                  />
                  <p className={styles.serviceCardTagLabel}>運用費0円</p>
                </div>
                <div className={styles.serviceCardTag} style={{margin: "1px 0"}}>
                  <StaticImage
                    className={styles.serviceCardIcon}
                    alt="差し替え無料"
                    src="../images/icon-check.png"
                  />
                  <p className={styles.serviceCardTagLabel}>差し替え無料</p>
                </div>
                <div className={styles.serviceCardTag} style={{margin: "1px 0"}}>
                  <StaticImage
                    className={styles.serviceCardIcon}
                    alt="SEO対策"
                    src="../images/icon-check.png"
                  />
                  <p className={styles.serviceCardTagLabel}>SEO対策</p>
                </div>
                <div className={styles.serviceCardTag} style={{margin: "1px 0"}}>
                  <StaticImage
                    className={styles.serviceCardIcon}
                    alt="レスポンシブ"
                    src="../images/icon-check.png"
                  />
                  <p className={styles.serviceCardTagLabel}>レスポンシブ</p>
                </div>
                {/* <Link to="/production"
                  className={styles.serviceReedMoreWrapper}
                  style={{right: "auto", bottom: "-15%", left: "-20%"}}
                  data-sal="slide-up"
                  data-sal-duration="1000"
                  data-sal-easing="ease-out-cubic"
                >
                  <div className={styles.serviceReedMoreButton}>
                    <StaticImage
                      className={styles.serviceReedMoreCushion}
                      alt="詳細を見る"
                      src="../images/cushion-blobs.png"
                    />
                    <p className={styles.serviceReedMoreText}>詳細<br/>を見る</p>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.serviceBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        <div className={styles.serviceCard}>
          <div className={styles.serviceCardBody}>
            <div className={styles.serviceCardHeader}>
              <h2 className={styles.serviceCardTitle} style={{margin: "20px 0 25px"}}>ライフコーチング</h2>
              <p className={styles.serviceCardPrice}>1時間<span className={styles.serviceCardPriceFee}>$50</span></p>
              {/* <p className={styles.serviceCardPrice} style={{fontWeight: "normal", margin: "5px 0 0"}}>(1時間あたり)</p> */}
            </div>
            <div
              className={styles.serviceCardTagsGrid}
              style={breakpoints.xs || breakpoints.sm || breakpoints.md
                ? {gridTemplateColumns: "repeat(1, 1fr)", borderRadius: "0% 35% 35% 0% / 0% 50% 50% 0%", padding: "15px", width: "240px"}
                : {borderRadius: "0% 30% 30% 0% / 0% 50% 50% 0%", width: "300px"}
              }
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease-out-cubic"
            >
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="フリーランスへの転身"
                  src="../images/icon-laptop.png"
                />
                <p className={styles.serviceCardTagLabel}>フリーランスへの転身</p>
              </div>
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="海外移住相談"
                  src="../images/icon-plane.png"
                />
                <p className={styles.serviceCardTagLabel}>海外移住相談</p>
              </div>
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="「好きなこと」の発見"
                  src="../images/icon-heart.png"
                />
                <p className={styles.serviceCardTagLabel}>好きなことの発見</p>
              </div>
              <div className={styles.serviceCardTag}>
                <StaticImage
                  className={styles.serviceCardIcon}
                  alt="ライフスタイルの提案"
                  src="../images/icon-home.png"
                />
                <p className={styles.serviceCardTagLabel}>ライフスタイルの提案</p>
              </div>
              <Link to="/coaching"
                className={styles.serviceReedMoreWrapper}
                style={breakpoints.xs || breakpoints.sm || breakpoints.md
                  ? {right: "-30%", bottom: "0"}
                  : {right: "-10%", bottom: "-20%"}
                }
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease-out-cubic"
              >
                <div className={styles.serviceReedMoreButton}>
                  <StaticImage
                    className={styles.serviceReedMoreCushion}
                    alt="詳細を見る"
                    src="../images/cushion-blobs.png"
                  />
                  <p className={styles.serviceReedMoreText}>詳細<br/>を見る</p>
                </div>
              </Link>
            </div>
          </div>
          <div
            className={styles.serviceCardImageWrapper}
            style={breakpoints.xs || breakpoints.sm || breakpoints.md
              ? {margin: "0 0 0 -110px"}
              : {margin: "0 0 0 -200px"}
            }
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              imgStyle={{borderRadius: "40% 0% 0% 40% / 50% 0% 0% 50%"}}
              alt="Border line"
              src="../images/service-life-coaching.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service
