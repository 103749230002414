import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/about.module.css'

const About = () => {
  const breakpoints = useBreakpoint()

  return (
    <div id="about" className={styles.aboutContainer}>
      <h1 className={styles.aboutTitleEn}>About Us</h1>
      <h2 className={styles.aboutTitleJp}>私たちがサポートします！</h2>
      <StaticImage
        className={styles.aboutLogo}
        alt="neutral"
        src="../images/logo-neutral-about.svg"
      />
      {breakpoints.xs || breakpoints.sm || breakpoints.md
      ?
      <p className={styles.aboutContent}>
        「<strong>NEUTRAL</strong>」は、私たちの理念です。<br/>
        <strong>中立、中道、対等、平等、バランスを保つ</strong>、という意味があります。<br/>
        <br/>
        私たち夫婦は、お互いの「やりたいこと」を尊重し、
        カフェ・雑貨店の経営、フリーランスへの転身、海外移住など「好きなこと」で生活ができるよう、協力し合ってきました。<br/>
        <br/>
        その中で見えてきたのは、自分たちの「好きなこと」を通じて、<strong>人が喜んでくれたり社会に貢献すること</strong>が、私たちにとって最大の幸せだということです。<br/>
        <br/>
        NEUTRALでは、「お客様と提供側」という一方通行の関係性ではなく、お客様の夢と私たちの経験・知識を掛け合わせることで、
        <strong>持続可能で「生きがい」にも繋がるお仕事</strong>を見つけるお手伝いができればと思っています。
      </p>
      :
      <p className={styles.aboutContent}>
        「<strong>NEUTRAL</strong>」は、私たちの理念です。<br/>
        <strong>中立、中道、対等、平等、バランスを保つ</strong>、<br/>
        という意味があります。<br/>
        <br/>
        <br/>
        私たち夫婦は、お互いの「やりたいこと」を尊重し、<br/>
        カフェ・雑貨店の経営、フリーランスへの転身、海外移住など<br/>
        「好きなこと」で生活ができるよう、協力し合ってきました。<br/>
        <br/>
        <br/>
        その中で見えてきたのは、自分たちの「好きなこと」を通じて、<br/>
        <strong>人が喜んでくれたり社会に貢献すること</strong>が、<br/>
        私たちにとって最大の幸せだということです。<br/>
        <br/>
        <br/>
        NEUTRALでは、「お客様と提供側」という一方通行の関係性ではなく、<br/>
        お客様の夢と私たちの経験・知識を掛け合わせることで、<br/>
        <strong>持続可能で「生きがい」にも繋がるお仕事</strong>を<br/>
        見つけるお手伝いができればと思っています。
      </p>
      }
    </div>
  )
}

export default About
