import * as React from 'react'
import Slider from 'react-slick'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/main-visual.module.css'

const MainVisual = () => {
  const sliderSettings = {
    fade: true,
    speed: 5000,
    infinite: true,
    autoplay: true,
    pauseOnHover: false,
  }

  return (
    <div id="top" className={styles.mainVisualContainer}>
      <Slider {...sliderSettings}>
        <StaticImage
          className={styles.mainVisualImage}
          alt="「好き」と「仕事」の交差点 | neutral"
          src="../images/main-visual-1.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="「好き」と「仕事」の交差点 | neutral"
          src="../images/main-visual-2.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="「好き」と「仕事」の交差点 | neutral"
          src="../images/main-visual-3.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="「好き」と「仕事」の交差点 | neutral"
          src="../images/main-visual-4.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="「好き」と「仕事」の交差点 | neutral"
          src="../images/main-visual-5.jpg"
        />
      </Slider>
      <div className={styles.mainVisualWrapper}>
        <h1 className={styles.mainVisualTitle}>「好き」と「仕事」の交差点</h1>
        <StaticImage
          className={styles.mainVisualLogo}
          alt="neutral"
          src="../images/logo-neutral-white.svg"
        />
        <h2 className={styles.mainVisualSubTitle}>CREATIVE x COACHING</h2>
        <a className={styles.mainVisualLine} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
          無料でLINE相談してみる
        </a>
      </div>
    </div>
  )
}

export default MainVisual
