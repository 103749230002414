import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import * as styles from '../styles/flow.module.css'

const Flow = () => {
  return (
    <div id="flow" className={styles.flowContainer}>
      <StaticImage
        className={styles.flowBorderImage}
        alt="Border line"
        src="../images/curve-clear.png"
      />
      <h1 className={styles.flowTitleEn}>Flow</h1>
      <h2 className={styles.flowTitleJp}>お申し込みからサポートまでの流れ</h2>
      <div className={styles.flowCardWrapper}>
        <div className={styles.flowCard}
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <div className={styles.flowCardHeader}>
            <div className={styles.flowCardHeaderNumberWrapper}>
              <StaticImage
                className={styles.flowCardHeaderCushion}
                alt="Number"
                src="../images/cushion-blobs.png"
              />
              <p className={styles.flowCardHeaderNumber}>1</p>
            </div>
            <p className={styles.flowCardHeaderText}>LINE、Zoomなどで無料相談</p>
          </div>
          <div className={styles.flowCardBody}>
            <span>
              各種プランについて詳しく知りたい、どのプランが良いか迷っているなど、<strong>無料</strong>にてご相談を承ります。<br/>
              ご相談方法は、LINEやEメールなど文書でのやりとり、Zoomなどのオンラインミーティング、どちらでも構いません。<br/>
              「<strong>無料でLINE相談してみる</strong>」ボタン、または「<strong>お問い合わせ</strong>」フォームより、ご連絡ください。
            </span>
          </div>
        </div>

        <div className={styles.flowCard}
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <div className={styles.flowCardHeader}>
            <div className={styles.flowCardHeaderNumberWrapper}>
              <StaticImage
                className={styles.flowCardHeaderCushion}
                alt="Number"
                src="../images/cushion-blobs.png"
              />
              <p className={styles.flowCardHeaderNumber}>2</p>
            </div>
            <p className={styles.flowCardHeaderText}>プランの決定、お申し込み</p>
          </div>
          <div className={styles.flowCardBody}>
            <span>
              プランが決定しましたら、ご希望の「お申し込み」ボタン、またはLINEでご連絡ください。<br/>
              また、無料相談時に直接お申し込みいただくことも可能です。
            </span>
          </div>
        </div>

        <div className={styles.flowCard}
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <div className={styles.flowCardHeader}>
            <div className={styles.flowCardHeaderNumberWrapper}>
              <StaticImage
                className={styles.flowCardHeaderCushion}
                alt="Number"
                src="../images/cushion-blobs.png"
              />
              <p className={styles.flowCardHeaderNumber}>3</p>
            </div>
            <p className={styles.flowCardHeaderText}>お支払い（海外送金 or クレジットカード）</p>
          </div>
          <div className={styles.flowCardBody}>
            <span>
              お支払い方法は、<strong>海外送金（
                <a href="https://wise.com/" target="_blank" rel="noopener noreferrer">WISE</a>
              ）</strong>または、<strong>クレジットカード決済（
                <a href="https://www.paypal.com/" target="_blank" rel="noopener noreferrer">PayPal</a>
              ）</strong>のどちらかをお選びください。<br/>
              クレジットカード（PayPal）決済の場合、購入金額の<strong>4.27% + 1USD</strong>の手数料を加算させていただきますので、ご留意ください。
            </span>
          </div>
        </div>

        <div className={styles.flowCard}
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <div className={styles.flowCardHeader}>
            <div className={styles.flowCardHeaderNumberWrapper}>
              <StaticImage
                className={styles.flowCardHeaderCushion}
                alt="Number"
                src="../images/cushion-blobs.png"
              />
              <p className={styles.flowCardHeaderNumber}>4</p>
            </div>
            <p className={styles.flowCardHeaderText}>お申し込み完了メールが届く</p>
          </div>
          <div className={styles.flowCardBody}>
            <span>
              お申し込みが完了すると、メールが届きます。<br/>
              プランや日時をご確認くださいませ。<br/>
              <strong>迷惑メール</strong>フォルダへ振り分けられてしまうケースがあるので、ご注意ください。
            </span>
          </div>
        </div>

        <div className={styles.flowCard}
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <div className={styles.flowCardHeader}>
            <div className={styles.flowCardHeaderNumberWrapper}>
              <StaticImage
                className={styles.flowCardHeaderCushion}
                alt="Number"
                src="../images/cushion-blobs.png"
              />
              <p className={styles.flowCardHeaderNumber}>5</p>
            </div>
            <p className={styles.flowCardHeaderText}>サービス開始</p>
          </div>
          <div className={styles.flowCardBody}>
            <span>
              事前のご要望を元に、お客様に合わせた<strong>オーダーメイドプラン</strong>を考え、<strong>マンツーマン</strong>のオンラインミーティングで進めさせていただきます。
            </span>
          </div>
        </div>
      </div>
      <StaticImage
        className={styles.flowBorderImage}
        alt="Border line"
        src="../images/curve-clear.png"
      />
    </div>
  )
}

export default Flow
