import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as styles from '../styles/blog-list.module.css'

const BlogList = () => {
  const data = useStaticQuery(graphql`
    query BlogListQuery {
      allContentfulBlogPost(
        sort: { fields: publishedDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            id
            title
            slug
            publishedDate(formatString: "YYYY.MM.DD")
            description {
              description
            }
            thumbnail {
              file {
                url
              }
            }
            tag {
              title
              slug
            }
          }
        }
      }
    }
  `)
  const blogPosts = data.allContentfulBlogPost.edges

  return (
    <div className={styles.blogListContainer}>
      <StaticImage
        className={styles.blogListBorderImage}
        alt="Border line"
        src="../images/curve-moss-top.png"
      />
      <h1 className={styles.blogListTitle}>NEUTRAL Blog</h1>
      <p className={styles.blogListText}>
        NEUTRALの最新情報や技術関連、<br/>
        プライベート、「好きを仕事に」している人への<br/>
        インタビュー記事などをお届けします
      </p>
      <div className={styles.blogListCardWrapper}>
        {blogPosts.map(({ node: post }) => (
          <div key={post.id} className={styles.blogListCard}>
            <Link to={`/blog/${post.slug}`} className={styles.blogListCardLink}>
              <div className={styles.blogListCardImageWrapper}>
                <img
                  className={styles.blogListCardImage}
                  alt={post.title}
                  src={post.thumbnail.file.url}
                />
                {post.tag && <div className={styles.blogListCardTag}>{`#${post.tag.title}`}</div>}
              </div>
            </Link>
            <p className={styles.blogListCardDate}>{post.publishedDate}</p>
            <h2 className={styles.blogListCardTitle}>{post.title}</h2>
            <p className={styles.blogListCardText}>{post.description.description}</p>
          </div>
        ))}
      </div>
      <Link to="/blogs" className={styles.blogListButton}>ブログ記事一覧へ</Link>
      <StaticImage
        className={styles.blogListBorderImage}
        alt="Border line"
        src="../images/curve-moss-bottom.png"
      />
    </div>
  )
}

export default BlogList
