import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/works.module.css'

const Works = () => {
  const breakpoints = useBreakpoint()

  return (
    <div className={styles.worksContainer}>
      <StaticImage
        className={styles.worksBorderImage}
        alt="Border line"
        src="../images/curve-clear.png"
      />
      <h1 className={styles.worksTitleEn}>Past Work</h1>
      <h2 className={styles.worksTitleJp}>クリエイティブ制作実績</h2>
      <p className={styles.worksText}>
        Webサイト / Webアプリ / ロゴ / バナー / 写真加工 / 動画編集 / 名刺 / フライヤー / パンフレットなどのクリエイティブ制作を行なっています。
      </p>
      <div className={styles.worksGrid}>
        <div className={styles.worksFirstRow}>
          <div className={styles.worksPcImage}
            data-sal={breakpoints.xs || breakpoints.sm || breakpoints.md ? "slide-right" : "slide-up"}
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="Mond PC image"
              src="../images/mond-pc.png"
            />
          </div>
          <div className={styles.worksMobileImage}
            data-sal={breakpoints.xs || breakpoints.sm || breakpoints.md ? "slide-left" : "slide-up"}
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="Mond Mobile image"
              src="../images/mond-mobile.png"
            />
          </div>
        </div>
        <div className={styles.worksSecondRow}>
          <div className={styles.worksLeftImage}
            data-sal={breakpoints.xs || breakpoints.sm || breakpoints.md ? "slide-right" : "slide-up"}
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="Flyer design image 1"
              src="../images/flyer-1.jpg"
            />
          </div>
          <div className={styles.worksRightImage}
            data-sal={breakpoints.xs || breakpoints.sm || breakpoints.md ? "slide-left" : "slide-up"}
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="Graphic design image 4"
              src="../images/instagram-4.jpg"
            />
          </div>
        </div>
      </div>
      <Link className={styles.worksLink} to="/past-work">
        制作実績を見る
      </Link>
    </div>
  )
}

export default Works
