// extracted by mini-css-extract-plugin
export var readmore = "staff-module--readmore--YpUE2";
export var readmoreCheck = "staff-module--readmore-check--eAKOT";
export var readmoreContent = "staff-module--readmore-content--q9FoD";
export var readmoreContentMask = "staff-module--readmore-content-mask--+141I";
export var readmoreLabel = "staff-module--readmore-label--CEG2G";
export var staffCard = "staff-module--staff-card--j23KZ";
export var staffContainer = "staff-module--staff-container--WpGWL";
export var staffHeader = "staff-module--staff-header--rjKBb";
export var staffImage = "staff-module--staff-image--PJh+n";
export var staffInner = "staff-module--staff-inner--p3kKj";
export var staffName = "staff-module--staff-name--q0CBp";