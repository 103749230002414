// extracted by mini-css-extract-plugin
export var worksBorderImage = "works-module--works-border-image--24I0J";
export var worksContainer = "works-module--works-container--IGxrS";
export var worksFirstRow = "works-module--works-first-row--VtLgR";
export var worksGrid = "works-module--works-grid--EhQBx";
export var worksLeftImage = "works-module--works-left-image--HuMFJ";
export var worksLink = "works-module--works-link--rTwvo";
export var worksMobileImage = "works-module--works-mobile-image--uGB20";
export var worksPcImage = "works-module--works-pc-image--snv8K";
export var worksRightImage = "works-module--works-right-image--3-1ox";
export var worksSecondRow = "works-module--works-second-row--AIIoa";
export var worksText = "works-module--works-text--Vqqe0";
export var worksTitleEn = "works-module--works-title-en--FrIh-";
export var worksTitleJp = "works-module--works-title-jp--AxcPJ";